import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { HereMapApiService } from 'src/apis/HereMapApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { AutoComplete } from 'src/components/Autocomplete/Autocomplete'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupOrgUnits } from 'src/store/thunks/setupOrgUnits'
import { throttle } from 'throttle-debounce'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid } from '@mui/material'

export type OrgUnitFormProps = ComponentProps<typeof StyledForm> & {
  onFormSubmitSuccess?: () => void
  isEditForm?: boolean
  editable?: boolean
  orgUnitId?: string
}

export const OrgUnitForm: React.FC<OrgUnitFormProps> = ({ onFormSubmitSuccess, isEditForm, editable, orgUnitId, ...props }) => {
  const [name, setName] = useState<string>('')
  const [latitude, setLatitude] = useState<string>('')
  const [longitude, setLongitude] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [allowUserToViewBookingName, setAllowUserToViewBookingName] = useState<boolean>(false)
  const [addressOptions, setAddressOptions] = useState<any[]>([])
  const selectedOrgUnit = useAppSelector(state => orgUnitsSelectors.selectById(state, orgUnitId || ''))

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const submitHandler = () => {
    const payload = {
      org_unit_name: name,
      org_unit_address: address,
      org_unit_lat: latitude,
      org_unit_lng: longitude,
      org_unit_show_booking_names: allowUserToViewBookingName ? '1' : '0',
    }

    if (isEditForm) {
      const editPayload = {
        ...payload,
        ORG_UNIT_ID: orgUnitId,
      }

      return CarshareApiService.post('editOrgUnit', editPayload)
    }

    return CarshareApiService.post('addOrgUnit', payload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (!isEditForm || !selectedOrgUnit) return

    setName(selectedOrgUnit.org_unit_name || '')
    setAddress(selectedOrgUnit.org_unit_address || '')
    setLatitude(selectedOrgUnit.org_unit_lat || '')
    setLongitude(selectedOrgUnit.org_unit_lng || '')
    setAllowUserToViewBookingName(selectedOrgUnit.org_unit_show_booking_names === '1')
  }, [isEditForm, selectedOrgUnit, orgUnitId])

  // Address Autocomplete component
  const throttledHereMapFetch = throttle(500, async (value) => {
    const results = await HereMapApiService.get('geocode', {
      q: value,
      in: 'countryCode:AUS,NZL',
    })

    setAddressOptions(results.items)
  })

  const onAddressChanged = (_: any, value: any) => {
    if (value && value.length > 3 && editable) {
      throttledHereMapFetch(value)
    }

    setAddress(value)
  }

  const onAddressSelected = (_: any, value: any) => {
    if (!value) return

    setAddress(value.title)
    setLatitude(value.position.lat)
    setLongitude(value.position.lng)
  }

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Org unit ${name} was ${isEditForm ? 'updated' : 'created'} successfully.`)
      dispatch(setupOrgUnits())
    }
  }, [complete])

  return (
    <StyledForm onSubmit={onSubmit} $isEditable={editable} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        <Grid item>
          {errorMessage && (
            <Alert severity={'error'} icon={false}>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Org unit name</TextFieldLabel>
            <TextField
              id="name-textfield"
              disabled={!editable}
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Address</TextFieldLabel>
            <AutoComplete
              id="address-selector"
              filterOptions={(x) => x}
              value={{ title: address }}
              placeholder="Search address"
              disabled={!editable}
              options={addressOptions}
              getOptionLabel={(option: any) => option.title}
              onInputChange={onAddressChanged}
              onChange={onAddressSelected}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.title === value.title
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel disabled>Latitude</TextFieldLabel>
            <TextField
              type="text"
              value={latitude}
              disabled
              id="latitude-textfield"
              onChange={(e) => {
                setLatitude(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel disabled>Longitude</TextFieldLabel>
            <TextField
              type="text"
              value={longitude}
              disabled
              id="longitude-textfield"
              onChange={(e) => {
                setLongitude(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <FormControlLabel
              label="Allow user to view booking name"
              control={
                <Checkbox
                  disableRipple
                  checked={allowUserToViewBookingName}
                  disabled={!editable}
                  onChange={(e) => setAllowUserToViewBookingName(e.target.checked)}
                />
              }
            />
          </StyledFormControl>
        </Grid>
        {editable && <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            {isEditForm ? 'Edit' : 'Add'} Org Unit
          </StyledSubmitButton>
        </Grid>}
      </Grid>
    </StyledForm >
  )
}
