import { ComponentProps, useEffect, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { useAlertContext } from 'src/components/Alert/AlertProvider'
import { Dropdown } from 'src/components/Dropdown/Dropdown'
import { DropdownItem } from 'src/components/Dropdown/DropdownItem'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { StyledForm, StyledFormControl, StyledSubmitButton } from 'src/fragments/StyledForm'
import { useApiRequest } from 'src/hooks/useApiRequest'
import { customersSelectors } from 'src/store/adapters/customers'
import { orgUnitsSelectors } from 'src/store/adapters/orgUnits'
import { useAppDispatch, useAppSelector } from 'src/store/store'
import { setupCustomerOrgUnits } from 'src/store/thunks/setupCustomerOrgUnits'
import { setupCustomers } from 'src/store/thunks/setupCustomers'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Checkbox, FormControlLabel, Grid } from '@mui/material'

export type CustomerFormProps = ComponentProps<typeof StyledForm> & {
  customerId?: string
  isEditForm?: boolean
  editable?: boolean
  onFormSubmitSuccess?: () => void
}

export const CustomerForm: React.FC<CustomerFormProps> = ({ customerId, isEditForm, editable, onFormSubmitSuccess, ...props }) => {
  const customerType = useAppSelector(state => state.user.ui_type) || ''
  const isWaipa = customerType === 'WAIPA'

  const orgUnits = useAppSelector((state) => orgUnitsSelectors.selectAll(state)).slice(1)
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const [orgUnitId, setOrgUnitId] = useState<string>(activeOrgUnit?.ORG_UNIT_ID || '')

  const activeCustomer = useAppSelector(state => customersSelectors.selectById(state, customerId || ''))
  const [firstName, setFirstName] = useState<string>(activeCustomer?.customer_first_name || '')
  const [lastName, setLastName] = useState<string>(activeCustomer?.customer_last_name || '')
  const [email, setEmail] = useState<string>('')
  const [externalId, setExternalId] = useState<string>(activeCustomer?.customer_identifier || '')
  const [birthYear, setBirthYear] = useState<number>(Number(activeCustomer?.customer_birth_year) || 2000)
  const [birthYearError, setBirthYearError] = useState<string | null>(null)
  const [hideCustomerNameFromOther, setHideCustomerNameFromOther] = useState<boolean>(false)

  const { loading, complete, errorMessage, request } = useApiRequest()
  const { setSuccessMessage } = useAlertContext()
  const dispatch = useAppDispatch()

  const orgUnitOptions = orgUnits.map(orgUnit => ({
    label: orgUnit.org_unit_name,
    orgUnitId: orgUnit.ORG_UNIT_ID,
  }))

  const submitHandler = () => {
    const payload = {
      customer_first_name: firstName,
      customer_last_name: lastName,
      customer_birth_year: birthYear,
      customer_identifier: externalId,
      customer_hide_booking_name: hideCustomerNameFromOther ? '1' : '0',
    }

    if (isEditForm) {
      const editPayload = {
        USER: activeCustomer?.USER || '',
        CUSTOMER_ID: customerId,
        ...payload,
      }

      return CarshareApiService.post('editCustomer', editPayload)
    }

    const addPayload = {
      ORG_UNIT_ID: orgUnitId,
      email: email,
      ...payload,
    }

    return CarshareApiService.post('addCustomer', addPayload)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    request(submitHandler)
  }

  useEffect(() => {
    if (!isEditForm || !activeCustomer) return

    setFirstName(activeCustomer.customer_first_name)
    setLastName(activeCustomer.customer_last_name)
    setExternalId(activeCustomer.customer_identifier)
    setBirthYear(Number(activeCustomer.customer_birth_year))
    setHideCustomerNameFromOther(activeCustomer.customer_hide_booking_name === '1')
  }, [isEditForm, activeCustomer])

  useEffect(() => {
    if (errorMessage) return

    if (complete && onFormSubmitSuccess) {
      onFormSubmitSuccess()
    }
  }, [errorMessage, complete, onFormSubmitSuccess])

  useEffect(() => {
    if (complete) {
      setSuccessMessage(`Customer ${firstName} ${lastName} was ${isEditForm ? 'updated' : 'created'} successfully.`)

      if (isEditForm && customerId) {
        dispatch(setupCustomerOrgUnits({ CUSTOMER_ID: customerId }))
      } else {
        dispatch(setupCustomers({}))
      }
    }
  }, [complete])

  return (
    <StyledForm $isEditable={editable} onSubmit={onSubmit} {...props}>
      <Grid container spacing={2} justifyContent={'center'} flexDirection={'column'}>
        {errorMessage && (<Grid item>
          <Alert severity={'error'} icon={false}>
            {errorMessage}
          </Alert>
        </Grid>
        )}
        {!isEditForm && <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Org Unit</TextFieldLabel>
            <Dropdown
              value={orgUnitOptions.find(orgUnit => orgUnit.orgUnitId === orgUnitId)?.label || ''}
              onSelectItem={(value) => { setOrgUnitId(value) }}
              disabled={!editable}
            >
              {orgUnitOptions.map(orgUnit => {
                return (
                  <DropdownItem key={orgUnit.orgUnitId} data-value={orgUnit.orgUnitId}>{orgUnit.label}</DropdownItem>
                )
              })}
            </Dropdown>
          </StyledFormControl>
        </Grid>}
        <Grid item>
          <StyledFormControl required={editable}>
            <TextFieldLabel>First Name</TextFieldLabel>
            <TextField
              id="first-name-textfield"
              required
              value={firstName}
              disabled={!editable}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl required={editable}>
            <TextFieldLabel>Last Name</TextFieldLabel>
            <TextField
              id="last-name-textfield"
              required
              value={lastName}
              disabled={!editable}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        {!isEditForm && <Grid item>
          <StyledFormControl required>
            <TextFieldLabel>Email</TextFieldLabel>
            <TextField
              type="email"
              id="email-textfield"
              required
              disabled={!editable}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>}
        {!isWaipa && <Grid item>
          <StyledFormControl required={editable}>
            <TextFieldLabel>Birth Year</TextFieldLabel>
            <TextField
              type="number"
              value={birthYear}
              error={!!birthYearError}
              disabled={!editable}
              helperText={birthYearError}
              onChange={(e) => {
                const value = Number(e.target.value)
                const maxYear = new Date().getFullYear() - 17
                const minYear = new Date().getFullYear() - 90

                if (value <= minYear || value >= maxYear) {
                  setBirthYearError(`Please enter a year between ${minYear} and ${maxYear}.`)
                } else {
                  setBirthYearError(null)
                }

                setBirthYear(value)
              }}
            />
          </StyledFormControl>
        </Grid>}
        <Grid item>
          <StyledFormControl>
            <TextFieldLabel>Identifier</TextFieldLabel>
            <TextField
              type="text"
              value={externalId}
              placeholder="External ID"
              disabled={!editable}
              onChange={(e) => {
                setExternalId(e.target.value)
              }}
            />
          </StyledFormControl>
        </Grid>
        <Grid item>
          <StyledFormControl>
            <FormControlLabel
              label="Hide booking name from others"
              control={
                <Checkbox
                  disableRipple
                  checked={hideCustomerNameFromOther}
                  disabled={!editable}
                  onChange={(e) => setHideCustomerNameFromOther(e.target.checked)}
                />
              }
            />
          </StyledFormControl>
        </Grid>
        {editable && <Grid item>
          <StyledSubmitButton
            primary
            fullWidth
            type="submit"
            disabled={loading}
          >
            {isEditForm ? 'Update' : 'Add'} Customer
          </StyledSubmitButton>
        </Grid>}
      </Grid>
    </StyledForm >
  )
}
