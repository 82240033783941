import { Customer } from 'src/types/Customer'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { customersAdapter } from '../adapters/customers'
import { setupCustomerAttributes } from '../thunks/setupCustomerAttributes'
import { setupCustomerOrgUnits } from '../thunks/setupCustomerOrgUnits'

export const initialState = customersAdapter.getInitialState()

export const { reducer, actions } = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    addMultipleCustomers: (state, action: PayloadAction<Customer[]>) => {
      customersAdapter.upsertMany(state, action.payload)
    },
    removeAllCustomers: (state) => {
      customersAdapter.removeAll(state)
    },
    replaceAllCustomers: (state, action: PayloadAction<Customer[]>) => {
      customersAdapter.setAll(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setupCustomerOrgUnits.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          const customerOrgUnits = action.payload.map((customer) => ({
            ORG_UNIT_ID: customer.ORG_UNIT_ID,
            MEMBER_ID: customer.MEMBER_ID,
          }))

          customersAdapter.updateOne(state, {
            id: action.payload[0].CUSTOMER_ID,
            changes: {
              ORG_UNITS: customerOrgUnits,
              customer_first_name: action.payload[0].customer_first_name,
              customer_last_name: action.payload[0].customer_last_name,
              customer_birth_year: action.payload[0].customer_birth_year,
              customer_name: action.payload[0].customer_name,
              customer_identifier: action.payload[0].customer_identifier,
              customer_hide_booking_name: action.payload[0].customer_hide_booking_name,
            },
          })
        } else if (action.payload && action.payload.length === 0) {
          customersAdapter.updateOne(state, {
            id: action.meta.arg.CUSTOMER_ID,
            changes: {
              ORG_UNITS: [],
            },
          })
        }
      })
      .addCase(setupCustomerAttributes.fulfilled, (state, action) => {
        if (action.payload) {
          const customerAttributes = action.payload.results

          customersAdapter.updateOne(state, {
            id: action.payload.CUSTOMER_ID,
            changes: {
              ATTRIBUTES: customerAttributes,
            },
          })
        }
      })
  },
})

export const { addMultipleCustomers, removeAllCustomers, replaceAllCustomers } = actions